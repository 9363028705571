import { TestAttempt } from './shared';
import { Tenant } from './tenant';
import { Values } from './type-utils';

export interface CreateEvaluationRequestBody {
  testAttempt: TestAttempt;
  tenant: Tenant;
}

export const AutomaticallySetFinalGrade = {
  All: 'all',
  PassingOnly: 'passingOnly',
  None: 'none',
} as const;
export type AutomaticallySetFinalGrade = Values<typeof AutomaticallySetFinalGrade>;
