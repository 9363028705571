import { ResourceList } from './shared';
import { Registration } from './registration';
import { ListParamsStrict, ListResourceScope, ParamValueCondition } from './list-params';

export interface ExamApproval {
  registrationId?: number;
  approved: boolean;
  approvalConsumed: boolean;
  readonly createdAt?: string;
  readonly updatedAt?: string;

  registration?: Registration;
}

export interface ExamApprovalVerificationRequestBody {
  password: string;
}

export interface VerifiedExamRegistration {
  proctorId: number;
  examName: string;
  startTime: string;
  approved: boolean;
}

export interface ExamApprovalListParams extends ListParamsStrict {
  registrations?: boolean;
  startTime?: ParamValueCondition;
  endTime?: ParamValueCondition;
  userEmail?: string;
  scope?: ListResourceScope;
}

export type ExamApprovalList = ResourceList<ExamApproval>;
